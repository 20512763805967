<template>
  <div class="sld_login">
    <div class="sld_login_header">
      <div class="content">
        <router-link tag="a" class="l_logo" :to="`/index`">
          <img src="../../../assets/new_logo.png" class="img" />
        </router-link>
        <div class="r_register_wrap">
          <!--          {{L['我已知悉？']}}-->
        </div>
      </div>
    </div>
    <div class="agreement_container">
      <h2 class="agreement_title">{{agreeContent.title}}</h2>
      <pre class="agreement_content" v-html="agreeContent.content"></pre>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { getCurrentInstance, reactive, onMounted } from 'vue';

export default {
  setup() {
    const router = useRouter()
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const agreeContent = reactive({
      content: '',
      title: ''
    })
    const agreementCode = reactive({
      agreementCode: 'card_pay_server_agreement'
    })
    const getInitData = () => {
      proxy.$get('/v3/system/front/agreement/detail', agreementCode).then(res => {
        if (res.state == 200) {
          agreeContent.content = res.data.content
          agreeContent.title = res.data.title
        }
      })
    }

    const goRegister = () => {
      router.push({
        path: '/register'
      })
    }
    onMounted(() => {
      getInitData()
    })

    return { L, agreeContent, goRegister }
  },
};
</script>
<style lang="scss" scoped>
.agreement_container{
  width: 800px;

}
.agreement_content{
  font-size: 15px;
  line-height: 35px;
  white-space:normal;
  word-break:break-all;
}

.sld_login {
  .sld_login_header {
    background: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);

    .content {
      display: flex;
      padding: 20px 0;
      align-items: center;
      width: 1200px;
      margin: auto;

      .l_logo {
        cursor: pointer;
        display: flex;
        margin-right: 70%;

        .img {
          display: inline-block;
          vertical-align: top;
          max-width: 190px;
          max-height: 43px;
        }

        .text {
          font-size: 0;
          display: inline-block;
          vertical-align: top;
          line-height: 48px;
          margin-left: 5px;
        }
      }

      .r_register_wrap {
        font-size: 14px;
      }
    }
  }
  .agreement_container {
    width: 1200px;
    margin: auto;
    margin-top: 60px;
    padding: 30px 20px 30px;
    pre {
      margin-top: 40px;
    }
    .agreement_title {
      text-align: center;
    }
  }
}


</style>
